





































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { isArray } from 'lodash';
import { AgGridVue } from '@ag-grid-community/vue';
import {
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  SelectionChangedEvent,
  ValueGetterParams,
} from '@ag-grid-community/core';
import SettingsService from '@/services/crm/settings.service';
import ContactService from '@/services/crm/contact.service';
import ActivityService from '@/services/crm/activity.service';
import SettingsModel from '@/models/crm/settings.model';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import UserModel from '@/models/user.model';
import CrmAppBar from '@/components/crm/app-bar.vue';
import ContentDialog from '@/components/content-dialog.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { UserTypeEnum } from '@/enums/crm/user-type.enum';
import DataGridFilter from '@/components/data-grid-filter.vue';
import DateRangeFilter from '@/components/date-range-filter.vue';
import dayjs from '@/plugins/dayjs';
import { IDateRangeConfig } from '@/interfaces/date-range-config.interface';
import { DateHelper } from '@/utils/helpers/date-helper';
import Tooltip from '@/components/tooltip.vue';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import ConversationModel from '@/models/crm/conversation.model';
import { GridHelper } from '@/utils/helpers/grid-helper';
import { EmailOriginEnum } from '@/enums/crm/email-origin.enum';
import { StringHelper } from '@/utils/helpers/string-helper';
import EmailService from '@/services/crm/email.service';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import { IKeyValue } from '@/interfaces/key-value.interface';
import HistoryTypeModel from '@/models/crm/history-type.model';
import GroupModel from '@/models/crm/group.model';

type DataGridFilterConfig = {
  keyword: string | null;
  period: (Date | undefined)[];
  contactType: ContactTypeEnum[] | null;
  attendant: number[] | null;
  origin: EmailOriginEnum[] | null;
  destination: string | null;
  groupArea: GroupModel | null;
  historyType: HistoryTypeModel | null;
};

enum ContactTypeEnum {
  Client = 'CLIENT',
  Prospect = 'PROSPECT',
}

interface ISelectOption<T> {
  code: T;
  description: string;
}

@Component({
  components: {
    DataGridFilter,
    DateRangeFilter,
    CrmAppBar,
    AgGridWrapper,
    AgGridVue,
    Tooltip,
    ContentDialog,
  },
})
export default class CrmemailReport extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.CrmEmailService)
  private emailService!: EmailService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  settings: SettingsModel | null = null;

  userContactInfo: UserContactInfo | null = null;

  dialogConfig: IKeyValue<IDialogConfig> = {
    export: {
      show: false,
      exportAll: false,
      exportCurrent: true,
    },
  };

  // #region Grid

  grid: GridReadyEvent | null = null;

  gridClient: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    itemsPerPage: 30,
    columnDefs: [
      GridHelper.getSelectionColDef({ headerCheckboxSelection: false }),
      {
        headerName: `${this.$t('crm.view.emailReport.grid.origin')}`,
        colId: 'origin',
        field: 'origem',
        valueGetter: (params): string => GridHelper.valueGetter(params, StringHelper.toTitleCase),
        maxWidth: 75,
        sortable: false,
      },
      {
        headerName: `${this.$t('crm.view.emailReport.grid.date')}`,
        colId: 'inclusionDate',
        field: 'inclusaoData',
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value, 'dateTime'),
        maxWidth: 145,
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.emailReport.grid.contactType')}`,
        colId: 'contactType',
        field: 'cnpj',
        maxWidth: 130,
        sortable: true,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            const type = params.data?.cnpj ? 'CLIENT' : 'PROSPECT';
            return this.$t(`crm.view.emailReport.filter.${type.toLowerCase()}`).toString();
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.emailReport.grid.legalName')}`,
        colId: 'legalName',
        sortable: false,
        valueGetter: (params: ValueGetterParams): string => {
          const isRowPinned = params.node && params.node.rowPinned;
          if (!isRowPinned) {
            if (params.data?.cnpj) {
              return params.data?.cliente?.nome || params.data?.cliente?.nomeFantasia;
            }
            if (params.data.prospect) {
              return params.data?.prospect?.razaoSocial || params.data?.prospect?.nome;
            }
          }
          return '';
        },
      },
      {
        headerName: `${this.$t('crm.view.emailReport.grid.subject')}`,
        colId: 'subject',
        field: 'assunto',
        flex: 0.75,
        sortable: false,
      },
      {
        headerName: `${this.$t('crm.view.emailReport.grid.description')}`,
        colId: 'message',
        field: 'mensagem',
        valueGetter: (params: ValueGetterParams): string => {
          const regExp = new RegExp(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g);
          return (GridHelper.valueGetter(params) || '').replace(regExp, ' ');
        },
        flex: 1.25,
        autoHeight: true,
        sortable: false,
      },
      {
        headerName: `${this.$t('crm.view.emailReport.grid.attachments')}`,
        colId: 'attachments',
        field: 'anexos',
        cellRenderer: (param): string => {
          let ret = '';
          let isValid = false;
          if (param != null && param.data != null && param.data.anexos != null && param.data.anexos !== '') {
            JSON.parse(param.data.anexos).forEach((anexo) => {
              const regex = RegExp(/.*(\.pdf|\.html|\.png|\.jpg|\.jpeg|\.tiff|\.gif).*$/gi);
              if (
                !isValid
                && (!anexo.mimeType.includes('image') || (anexo.mimeType.includes('image') && anexo.tamanho <= 1024000))
              ) {
                isValid = regex.test(anexo.nome);
                if (isValid) {
                  ret += '<a id="open">Visualizar</a><br><br>';
                }
              }
            });
            ret += '<a id="download">Download</a>';
          }
          return ret;
        },
        onCellClicked: (event): void => {
          if (event.data != null && event.data.anexos != null) {
            JSON.parse(event.data.anexos).forEach((anexo) => {
              const regex = RegExp(/.*(\.pdf|\.html|\.png|\.jpg|\.jpeg|\.tiff|\.gif).*$/gi);
              if (
                event.event?.target != null
                && (<HTMLTextAreaElement>event.event?.target).id === 'open'
                && regex.test(anexo.nome)
                && (!anexo.mimeType.includes('image') || (anexo.mimeType.includes('image') && anexo.tamanho <= 1024000))
              ) {
                const w = window.open('');
                w?.document.write(`<iframe allowfullscreen webkitallowfullscreen mozallowfullscreen
                width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0"
                scrolling="no" src="${anexo.base64}"></iframe>`);
              } else if (event.event?.target != null && (<HTMLTextAreaElement>event.event?.target).id === 'download') {
                const element = document.createElement('a');
                element.setAttribute('href', anexo.base64);
                element.setAttribute('download', anexo.nome);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
              }
            });
          }
        },
        flex: 0.5,
        autoHeight: true,
        sortable: false,
      },
      {
        headerName: `${this.$t('crm.view.emailReport.grid.attendant')}`,
        colId: 'attendant',
        field: 'nomeAtendente',
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.emailReport.grid.recipients')}`,
        colId: 'destination',
        field: 'destinatarios',
        valueGetter: (params): string[] => [...(params.data.destinatarios || []), ...(params.data.copias || [])],
        autoHeight: true,
        sortable: false,
      },
      {
        headerName: `${this.$t('crm.view.emailReport.grid.groupArea')}`,
        colId: 'grupoArea',
        field: 'grupoArea.descricao',
        flex: 0.5,
        autoHeight: true,
        sortable: false,
      },
      {
        headerName: `${this.$t('crm.view.emailReport.grid.historyType')}`,
        colId: 'tipoHistorico',
        field: 'tipoHistorico.descricao',
        flex: 0.6,
        autoHeight: true,
        sortable: false,
      },
    ],
  };

  serverSideDatasource: IServerSideDatasource = {
    getRows: this.getRows,
  };

  items: ConversationModel[] = [];

  itemsClient: ConversationModel[] = [];

  selected: ConversationModel[] = [];

  // #endregion

  // #region filters

  filterOpen = 1;

  multipleFilterChanged = false;

  predefinedPeriodRanges: IDateRangeConfig[] = this.getDateRanges();

  filters: DataGridFilterConfig = {
    period: [dayjs().startOf('month').toDate(), dayjs().toDate()],
    contactType: null,
    attendant: null,
    keyword: null,
    origin: null,
    destination: null,
    groupArea: null,
    historyType: null,
  };

  totalRows = 0;

  isExporting = false;

  originOptions: ISelectOption<EmailOriginEnum>[] = [];

  attendantOptions: UserModel[] = [];

  groupAreaOptions: GroupModel[] = [];

  historyTypeOptions: HistoryTypeModel[] = [];

  contactTypeOptions: ISelectOption<ContactTypeEnum>[] = [];

  // #endregion

  async mounted(): Promise<void> {
    const loader = this.$loading.show();
    try {
      this.settings = await this.settingsService.getSettings();
      this.userContactInfo = await this.contactService.getLoggedUserContactInfo();
      await this.loadFilterOptions();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  private async getRows(params: IServerSideGetRowsParams) {
    const sortMapping = {
      inclusionDate: 'e.inclusaoData',
      contactType: 'e.cnpj',
      attendant: 'e.atendente.nome',
    };
    const inverseSorting = ['contactType'];

    const page = this.isExporting ? 1 : (params.request.endRow || 1) / (this.gridSettings.itemsPerPage || 1);
    let orderBy: string | undefined;

    if (params.request.sortModel?.length) {
      const mappedSort = params.request.sortModel.map((x) => {
        let { sort } = x;
        if (inverseSorting.includes(x.colId)) {
          sort = sort === 'asc' ? 'desc' : 'asc';
        }
        return `${sortMapping[x.colId]} ${sort}`;
      });

      orderBy = mappedSort.join(', ');
    }

    try {
      this.filters.period[0] = dayjs(this.filters.period[0]).startOf('day').toDate();
      this.filters.period[1] = dayjs(this.filters.period[1]).endOf('day').toDate();
      if (this.userContactInfo === null) this.userContactInfo = await this.contactService.getLoggedUserContactInfo();
      if (this.userContactInfo !== null && !this.notNormalUser) {
        this.filters.attendant = [this.userContactInfo.id];
      }

      const response = await this.emailService.queryEmails(
        page,
        this.isExporting ? -1 : this.gridSettings.itemsPerPage || 1,
        this.filters as unknown,
        orderBy,
      );

      params.success({
        rowData: response.results,
        rowCount: response.total,
      });

      if (this.isExporting && this.gridClient) {
        const onlySelected = false;
        this.gridClient.api.setRowData(response.results);
        this.gridClient.api.exportDataAsExcel({
          onlySelected,
          allColumns: true,
          author: 'IBtech',
          sheetName: 'E-mail',
          fileName: EmailService.generateEmailsReportExportFilename(new Date()),
        });
      }
      this.gridClient?.api.setRowData([]);
      this.isExporting = false;
    } catch (err) {
      this.$notify.error(err && (err as Error).message);

      params.fail();
    }
  }

  async onFilterChange(type: string): Promise<void> {
    if (type === 'multiple' && !this.multipleFilterChanged) {
      return;
    }

    if (this.settings === null) this.settings = await this.settingsService.getSettings();
    if (this.userContactInfo === null) this.userContactInfo = await this.contactService.getLoggedUserContactInfo();
    this.applyFilter();
  }

  async applyFilter(): Promise<void> {
    this.multipleFilterChanged = false;

    try {
      if (this.grid?.api) {
        this.grid.api.deselectAll();
        this.selected = [];

        this.grid.api.paginationGoToFirstPage();
        this.grid.api.refreshServerSideStore({});
      }
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: ConversationModel[]): void {
    this.selected = selected;
  }

  onExport(): void {
    this.dialogConfig.export.show = true;
  }

  onExportConfirm(selected: ConversationModel[]): void {
    if (this.grid) {
      if (this.itemsClient.length > 0 && this.dialogConfig.export.exportCurrent) {
        this.grid.api.purgeServerSideCache();
      }
      if (this.dialogConfig.export.exportAll) {
        this.isExporting = true;
        const loader = this.$loading.show();
        try {
          this.grid.api.purgeServerSideCache();
        } catch (err) {
          this.$notify.error(err && (err as Error).message);
        } finally {
          loader.hide();
        }
      } else {
        const onlySelected = !!selected.length && this.items.length !== selected.length;
        const columnKeys = this.gridSettings.columnDefs
          .filter((x) => !x.checkboxSelection)
          .map((x) => x.colId || x.field || '');
        this.grid.api.exportDataAsExcel({
          onlySelected,
          columnKeys,
          allColumns: true,
          author: 'IBtech',
          sheetName: 'E-mails',
          fileName: EmailService.generateEmailsReportExportFilename(new Date()),
        });
      }
    }
    this.dialogConfig.export.show = false;
  }

  onExportAllChange(): void {
    if (this.dialogConfig.export.exportAll) {
      this.dialogConfig.export.exportCurrent = false;
    }
  }

  onExportCurrentChange(): void {
    if (this.dialogConfig.export.exportCurrent) {
      this.dialogConfig.export.exportAll = false;
    }
  }

  onCloseExportDialogClose(): void {
    this.dialogConfig.export.show = false;
    this.dialogConfig.export.exportAll = false;
    this.dialogConfig.export.exportCurrent = true;
  }

  get activeFilters(): number {
    let active = 0;
    const filtersToIgnore: string[] = [];
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      const filter = this.filters[key];
      switch (key) {
        default:
          if (!filtersToIgnore.includes(key) && filter && !(isArray(filter) && !filter.length)) {
            active += 1;
          }
      }
    });

    return active;
  }

  get exportDialogTitle(): string {
    return this.$t('crm.view.emailReport.dialog.export.title').toString();
  }

  private async loadFilterOptions(): Promise<void> {
    this.originOptions = this.getOriginOptions();
    this.contactTypeOptions = this.getContactTypeOptions();
    this.attendantOptions = await this.getAttendants();
    this.groupAreaOptions = await this.getGroupAreas();
    this.historyTypeOptions = await this.getHistoryTypes();
  }

  get notNormalUser(): boolean {
    return this.settings?.loggedUserType !== UserTypeEnum.Normal;
  }

  private getOriginOptions(): ISelectOption<EmailOriginEnum>[] {
    return [
      {
        code: EmailOriginEnum.Contact,
        description: `${this.$t('crm.view.emailReport.filter.contact')}`,
      },
      {
        code: EmailOriginEnum.Order,
        description: `${this.$t('crm.view.emailReport.filter.order')}`,
      },
      {
        code: EmailOriginEnum.Calendar,
        description: `${this.$t('crm.view.emailReport.filter.calendar')}`,
      },
    ];
  }

  private async getAttendants(): Promise<UserModel[]> {
    return this.activityService.getAttendants();
  }

  private async getGroupAreas(): Promise<GroupModel[]> {
    return this.activityService.getGroups();
  }

  private async getHistoryTypes(): Promise<HistoryTypeModel[]> {
    return this.activityService.getHistoryTypes();
  }

  private getContactTypeOptions(): ISelectOption<ContactTypeEnum>[] {
    return [
      {
        code: ContactTypeEnum.Client,
        description: this.$t('crm.view.emailReport.filter.client').toString(),
      },
      {
        code: ContactTypeEnum.Prospect,
        description: this.$t('crm.view.emailReport.filter.prospect').toString(),
      },
    ];
  }

  private getDateRanges(): IDateRangeConfig[] {
    return [
      {
        name: `${this.$t('global.today')}`,
        ...DateHelper.getTodayPeriod(),
      },
      {
        name: `${this.$t('global.yesterday')}`,
        ...DateHelper.getYesterdayPeriod(),
      },
      {
        name: `${this.$t('global.currentMonth')}`,
        ...DateHelper.getCurrentMonthPeriod(),
      },
      {
        name: `${this.$t('global.lastMonth')}`,
        ...DateHelper.getLastMonthsPeriod(1),
      },
      {
        name: `${this.$t('global.lastThreeMonths')}`,
        ...DateHelper.getLastMonthsPeriod(3),
      },
      {
        name: `${this.$t('global.lastSixMonths')}`,
        ...DateHelper.getLastMonthsPeriod(6),
      },
      {
        name: `${this.$t('global.lastYear')}`,
        ...DateHelper.getLastYearsPeriod(1),
      },
      {
        name: `${this.$t('global.currentYear')}`,
        ...DateHelper.getCurrentYearPeriod(),
      },
    ];
  }
}
